import CaseNumber from '../../common/list-view/data-table/custom-columns/case-number'
import Currency from '../../common/list-view/data-table/custom-columns/currency'
import Expiration from '../../common/list-view/data-table/custom-columns/expiration'
import Status from '../../common/list-view/data-table/custom-columns/status'
import CardPrefix from '../../common/list-view/data-table/custom-columns/card-prefix'
import CardSuffix from '../../common/list-view/data-table/custom-columns/card-suffix'
import AuthCode from '../../common/list-view/data-table/custom-columns/auth-code'
import TransactionTs from '../../common/list-view/data-table/custom-columns/transaction-ts'
import AlertTs from '../../common/list-view/data-table/custom-columns/alert-ts'
import TransactionId from '../../common/list-view/data-table/custom-columns/transaction-id'

import { rangeChoices } from '../../common/list-view/search/add-dynamic-date-ranges'

import DataAdapter from '../../common/list-view/data-handler/interfaces/data-adapter-interface'

const dataAdapter: DataAdapter = {
  name: 'alerts',
  pagination: {
    page: 1,
    perPage: 50
  },
  columns: {
    portal: {
      type: 'string',
      title: 'Portal',
      name: 'portal',
      path: 'portal',
      order: 1
    },
    caseId: {
      type: 'string',
      title: 'Case ID',
      name: 'caseId',
      path: 'caseId',
      order: 2,
      format: CaseNumber
    },
    expiration: {
      type: 'date',
      title: 'Expires',
      name: 'expiration',
      path: 'expiration',
      order: 3,
      sortable: true,
      sortDefault: 'asc',
      rangeStartName: 'expiration_after',
      rangeEndName: 'expiration_before',
      format: Expiration
    },
    status: {
      type: 'string',
      title: 'Status',
      name: 'status',
      path: 'status',
      order: 4,
      format: Status
    },
    amount: {
      type: 'string',
      title: 'Amount',
      name: 'amount',
      path: 'amount',
      order: 5,
      sortable: true,
      format: Currency
    },
    cardPrefix: {
      type: 'string',
      title: 'Card Prefix',
      name: 'cardPrefix',
      path: 'cardPrefix',
      order: 6,
      sortable: true,
      format: CardPrefix
    },
    cardSuffix: {
      type: 'string',
      title: 'Card Suffix',
      name: 'cardSuffix',
      path: 'cardSuffix',
      order: 7,
      sortable: true,
      format: CardSuffix
    },
    transactionTs: {
      type: 'date',
      title: 'Txn Date',
      name: 'transactionTs',
      path: 'transactionTs',
      order: 8,
      sortable: true,
      rangeStartName: 'transactionTs_after',
      rangeEndName: 'transactionTs_before',
      format: TransactionTs
    },
    alertTs: {
      type: 'date',
      title: 'Alert Date',
      name: 'alertTs',
      path: 'alertTs',
      order: 9,
      sortable: true,
      defaultRange: rangeChoices.lastFortyFiveDays,
      rangeStartName: 'alertTs_after',
      rangeEndName: 'alertTs_before',
      format: AlertTs
    },
    merchant: {
      type: 'number[]',
      title: 'Merchant',
      name: 'merchant',
      path: 'merchant[name]',
      order: 10,
      sortable: true
    },
    descriptor: {
      type: 'string',
      title: 'Descriptor',
      name: 'displayDescriptor',
      path: 'displayDescriptor',
      order: 11
    },
    outcome: {
      type: 'number[]',
      title: 'Outcome',
      name: 'outcome',
      path: 'outcome',
      order: 12
    },
    transactionId: {
      type: 'string',
      title: 'Transaction ID',
      name: 'transactionId',
      path: 'transactionId',
      order: 13,
      format: TransactionId
    },
    authCode: {
      type: 'string',
      title: 'Auth Code',
      name: 'authCode',
      path: 'authCode',
      order: 14,
      sortable: false,
      format: AuthCode
    },
    alertStatus: {
      type: 'string',
      title: 'Alert Status',
      name: 'alertStatus',
      path: 'alertStatus',
      default: 'new'
    },
    professionalService: {
      type: 'string',
      title: 'Professional Service',
      name: 'professionalService',
      path: 'professionalService'
    }
  }
}

export default dataAdapter
