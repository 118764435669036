import React from 'react'
import { MoreDots } from '@siftscience/focus-components/icons'
import HeaderMenu from './header-menu'

import ClientsIcon from '@material-ui/icons/Group'
import DataIcon from '@material-ui/icons/Publish'
import CloudUpload from '@material-ui/icons/CloudUpload'
import MidsIcon from '@material-ui/icons/Dialpad'
import MerchantIcon from '@material-ui/icons/Store'
import LoginIcon from '@material-ui/icons/VpnKey'
import ReasonCodesIcon from '@material-ui/icons/Memory'
import ModifiersIcon from '@material-ui/icons/Polymer'
import DataSourcesIcon from '@material-ui/icons/SwapVerticalCircle'
import UsersRolesIcon from '@material-ui/icons/Person'
import InternalReportsIcon from '@material-ui/icons/Toys'
import MidTrackerIcon from '@material-ui/icons/Timeline'
import InquiryDefaultsIcon from '@material-ui/icons/FilterNone'
import SectionsIcon from '@material-ui/icons/DynamicFeed'
import SectionMappingsIcon from '@material-ui/icons/Map'
import FieldsIcon from '@material-ui/icons/TextFields'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

const menuItems = [
  { href: '/admin/clients', text: 'Clients', icon: <ClientsIcon /> },
  { href: '/data_uploads', text: 'Data Upload Tool', icon: <DataIcon /> },
  {
    href: '/modern_dispute_upload/new',
    text: 'Modern Dispute Upload',
    icon: <CloudUpload />
  },
  { href: '/admin/mids', text: 'MIDs', icon: <MidsIcon /> },
  { href: '/admin/merchants', text: 'Merchants', icon: <MerchantIcon /> },
  { href: '/admin/logins', text: 'Logins', icon: <LoginIcon /> },
  { href: '/reason_codes', text: 'Reason Codes', icon: <ReasonCodesIcon /> },
  { href: '/modifiers', text: 'Modifiers', icon: <ModifiersIcon /> },
  { href: '/data_sources', text: 'Data Sources', icon: <DataSourcesIcon /> },
  { href: '/users', text: 'Users/Roles', icon: <UsersRolesIcon /> },
  {
    href: '/internal/reports',
    text: 'Internal Reports',
    icon: <InternalReportsIcon />
  },
  { href: '/mid_tracker', text: 'MID Tracker', icon: <MidTrackerIcon /> },
  {
    href: '/ui/inquiry-defaults',
    text: 'Inquiry Defaults',
    icon: <InquiryDefaultsIcon />
  },
  { href: '/sections', text: 'Sections', icon: <SectionsIcon /> },
  {
    href: '/section_mappings',
    text: 'Section Mappings',
    icon: <SectionMappingsIcon />
  },
  { href: '/fields', text: 'Fields', icon: <FieldsIcon /> },
  {
    href: '/det/template_dumps',
    text: 'Template Dumps',
    icon: <CloudDownloadIcon />
  },
  {
    href: '/det/template_imports',
    text: 'Template Imports',
    icon: <CloudUpload />
  }
]

const TOOLTIP_TITLE = 'Admin menu'

const AdminMenu = () => (
  <HeaderMenu
    IconComponent={MoreDots}
    menuItems={menuItems}
    popupId="admin"
    tooltipTitle={TOOLTIP_TITLE}
  />
)

export default AdminMenu
