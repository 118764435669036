import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '320px',
    minHeight: '100vh',
    paddingTop: '16px',
    boxShadow: '0px 1px 4px 0px #24242429'
  }
}))

const Navigation = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div>navigation</div>
    </div>
  )
}

export default Navigation
