import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'
import { ProcessorDTO } from '../dto/processor'

export const useProcessors = () => {
  const {
    data: processorsData,
    isLoading: isProcessorsLoading,
    refetch: refetchProcessors
  } = useQuery(
    'processors-list',
    () => axios.get<ProcessorDTO[]>('/det/tooling/merchants/processors'),
    {
      refetchOnWindowFocus: false
    }
  )

  return {
    processorsData: processorsData?.data as ProcessorDTO[],
    isProcessorsLoading,
    refetchProcessors
  }
}
