import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { NotificationProvider } from './common/notifications/NotificationContext'

import Theme from './theme'
import GlobalStyleOverrides from './global-style-overrides'
import ErrorBoundary from './error-boundary'
import IdleChecker from './common/idle-checker/idle-checker'
import { CheckboxProvider } from './common/list-view/common/checkboxes/checkbox-context'

import Alerts from './pages/alerts/alerts'
import Disputes from './pages/disputes/disputes'
import Invoices from './pages/invoices/invoices'
import Transactions from './pages/transactions/transactions'
import Reports from './pages/reports/reports'
import Looker from './pages/reports/looker'

import SessionLogoutForm from './pages/sessions/SessionLogoutForm'
import SessionLoginForm from './pages/sessions/SessionLoginForm'
import SessionResetForm from './pages/sessions/SessionResetForm'
import SessionNewPasswordForm from './pages/sessions/SessionNewPasswordForm'

import CaidsRouter from './pages/caids/router'
import InquiryDefaultsRouter from './pages/inquiry-defaults/router'
import MidMerchantGroupsRouter from './pages/mid-merchant-groups/router'
import Users from './pages/users'
import EvidenceConfiguration from './pages/evidence-configuration/index'
import DETEdit from './pages/DET-edit'
import TemplateTooling from './pages/template-tooling'
import DETEditAdditions from './pages/DET-edit-additions'
import ResponsePreview from './pages/response-preview'
import CurrentUserProvider from './contexts/CurrentUserContext'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

const isProduction = process.env.NODE_ENV === 'production'

library.add(fas)

const queryClient = new QueryClient()

export const App = () => {
  return (
    <>
      <CurrentUserProvider>
        <CssBaseline />
        <GlobalStyleOverrides />
        <QueryClientProvider client={queryClient}>
          <Theme>
            <ErrorBoundary>
              <CheckboxProvider>
                <NotificationProvider>
                  <SnackbarProvider maxSnack={5}>
                    <BrowserRouter>
                      <Switch>
                        <Route exact path="/disputes">
                          <Redirect to="/ui/disputes" />
                        </Route>
                        <Route exact path="/ui/disputes" component={Disputes} />
                        <Route exact path="/ui/alerts" component={Alerts} />
                        <Route exact path="/ui/invoices" component={Invoices} />
                        <Route
                          exact
                          path="/ui/transactions"
                          component={Transactions}
                        />
                        <Route exact path="/ui/reports" component={Reports} />
                        <Route
                          exact
                          path="/ui/looker/:report"
                          component={Looker}
                        />
                        <Route exact path="/users" component={Users} />
                        <Route
                          exact
                          path="/ui/evidence_configuration"
                          component={EvidenceConfiguration}
                        />
                        <Route
                          exact
                          path="/ui/logout"
                          component={SessionLogoutForm}
                        />
                        <Route
                          exact
                          path="/ui/login"
                          component={SessionLoginForm}
                        />
                        <Route
                          exact
                          path="/ui/reset"
                          component={SessionResetForm}
                        />
                        <Route
                          exact
                          path="/ui/new-password/:userId/:passwordResetToken"
                          component={SessionNewPasswordForm}
                        />
                        <Route
                          exact
                          path="/ui/disputes/:id/response_preview"
                          component={ResponsePreview}
                        />
                        <Route
                          exact
                          path="/ui/disputes/:id/det_edit"
                          component={DETEdit}
                        />
                        <Route
                          exact
                          path="/ui/disputes/:id/det_edit_additions"
                          component={DETEditAdditions}
                        />
                        <Route
                          exact
                          path="/ui/template-tooling"
                          component={TemplateTooling}
                        />
                        {InquiryDefaultsRouter()}
                        {CaidsRouter()}
                        {MidMerchantGroupsRouter()}
                      </Switch>
                    </BrowserRouter>
                    <IdleChecker />
                  </SnackbarProvider>
                </NotificationProvider>
              </CheckboxProvider>
            </ErrorBoundary>
          </Theme>
          {!isProduction && <ReactQueryDevtools />}
        </QueryClientProvider>
      </CurrentUserProvider>
    </>
  )
}
