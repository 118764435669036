import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ProcessorDTO } from '../dto/processor'
import { useProcessors } from '../hooks/useProcessor'
import { NONE_MERCHANT, useMerchantContext } from './merchant-context'

export const NONE_PROCESSOR = { id: 0, name: 'None' }

const addNoneToList = processors => {
  return [NONE_PROCESSOR, ...processors]
}

interface IProcessorContext {
  processorList?: ProcessorDTO[]
  isProcessorsLoading?: boolean
  displayProcessorList?: ProcessorDTO[]
  selectedProcessor?: ProcessorDTO
  setSelectedProcessor?: (processorDTO: ProcessorDTO) => void
}

const ProcessorContext = createContext<IProcessorContext>({})

export const ProcessorProvider = ({ children }) => {
  const { processorsData, isProcessorsLoading } = useProcessors()
  const { selectedMerchant } = useMerchantContext()
  const [displayProcessorList, setDisplayProcessorList] = useState<
    ProcessorDTO[]
  >([])
  const [selectedProcessor, setSelectedProcessor] = useState<ProcessorDTO>()

  useEffect(() => {
    if (!processorsData) {
      return
    }

    if (selectedMerchant?.name === NONE_MERCHANT.name) {
      setDisplayProcessorList(addNoneToList(processorsData))
    } else if (selectedMerchant) {
      setDisplayProcessorList(addNoneToList(selectedMerchant.processors || []))
    }
  }, [selectedMerchant, processorsData])

  useEffect(() => {
    if (
      selectedMerchant &&
      selectedProcessor &&
      selectedMerchant.name !== NONE_MERCHANT.name
    ) {
      const merchantHasProcessor = selectedMerchant.processors?.find(
        processor => processor.id === selectedProcessor.id
      )

      if (!merchantHasProcessor) {
        setSelectedProcessor(NONE_PROCESSOR)
      }
    }
  }, [selectedMerchant, selectedProcessor, setSelectedProcessor])

  const contextValue = useMemo(
    () => ({
      processorList: processorsData,
      isProcessorsLoading,
      displayProcessorList,
      selectedProcessor,
      setSelectedProcessor
    }),
    [
      processorsData,
      isProcessorsLoading,
      displayProcessorList,
      selectedProcessor,
      setSelectedProcessor
    ]
  )

  return (
    <ProcessorContext.Provider value={contextValue}>
      {children}
    </ProcessorContext.Provider>
  )
}

export const useProcessorContext = () => {
  return useContext(ProcessorContext)
}
