import React from 'react'
import { Title } from '@siftscience/focus-components/text'
import { Select } from '@siftscience/focus-components/select'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '4px',
    '& .focus-select-button': {
      width: '220px'
    }
  }
}))

interface DropdownItem {
  name: string
  id: number
}

interface TemplateToolingHeaderDropdownProps {
  title: string
  placeholder?: string
  items: DropdownItem[]
  selectedItem?: DropdownItem
  onSelect: (item: DropdownItem) => void
}

const TemplateToolingHeaderDropdown = ({
  title,
  placeholder,
  items,
  selectedItem,
  onSelect
}: TemplateToolingHeaderDropdownProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Title size="xsmall" color="secondary">
        {title}
      </Title>
      <Select
        items={items}
        getItemLabel={(item: DropdownItem) => item.name}
        selectedItem={items.find(item => item.id === selectedItem?.id)}
        onChange={onSelect}
        placeholder={placeholder}
        variant="secondary"
        strategy="fixed"
        stretch
      />
    </div>
  )
}

export default TemplateToolingHeaderDropdown
