import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Text } from '@siftscience/focus-components/text'
import { Escalate } from '@siftscience/focus-components/icons'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1',
    gap: '16px',
    marginTop: isInitialView => (isInitialView ? '64px' : '32px')
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px 0px #24242429',
    width: '48px',
    height: '48px',
    borderRadius: '24px'
  },
  icon: {
    width: '24px',
    transform: 'rotate(90deg)'
  }
}))

const MainView = (): React.ReactElement => {
  const isInitialView = true // TODO to be replaced during next stages
  const classes = useStyles(isInitialView)

  return (
    <div className={classes.wrapper}>
      {isInitialView ? (
        <>
          <Text size="medium">Select a template to start editing</Text>
          <div className={classes.iconWrapper}>
            <Escalate className={classes.icon} color="#295DED" />
          </div>
        </>
      ) : null}
    </div>
  )
}

export default MainView
