import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'
import { MerchantDTO } from '../dto/merchant'

export const useMerchants = () => {
  const {
    data: merchantsData,
    isLoading: isMerchantsLoading,
    refetch: refetchMerchants
  } = useQuery(
    'merchants-list',
    () => axios.get<MerchantDTO[]>('/det/tooling/merchants'),
    {
      refetchOnWindowFocus: false
    }
  )

  return {
    merchantsData: merchantsData?.data as MerchantDTO[],
    isMerchantsLoading,
    refetchMerchants
  }
}
