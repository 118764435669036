import React from 'react'
import { MerchantProvider } from './merchant-context'
import { ProcessorProvider } from './processor-context'

// Combines all template-tooling-related providers
export const TemplateToolingProvider = ({ children }) => (
  <MerchantProvider>
    <ProcessorProvider>{children}</ProcessorProvider>
  </MerchantProvider>
)
